import React from "react";
import classNames from "classnames";
import "./leaderboard.css";
import {useState, useEffect} from 'react';
import {Circuit, CircuitStatuses, DungeonStatus, TeamCircuitStatus, BossStatus, TrashStatus} from '../types'

export interface LeaderBoardProps {
    circuits: Circuit[]
    defaultCircuit: string
    fetchStatuses: (circuitId: string) => Promise<CircuitStatuses>
}

const renderSecondsAsTime = (seconds: number) => {
    if (seconds <= 0) {
        return '-';
    }

    let remaining = seconds

    const h = Math.floor(remaining / (60 * 60))
    remaining -= (h * 60 * 60)

    const m = Math.floor(remaining / 60)
    remaining -= m * 60

    const s = remaining

    const hStr = `${h}`.padStart(2, '0');
    const mStr = `${m}`.padStart(2, '0');
    const sStr = `${s}`.padStart(2, '0');

    return  `${hStr}:${mStr}:${sStr}`;
}

/**
 * Page component for HCAS Leaderboard.
 */
const LeaderBoard = ({
    circuits,
    defaultCircuit,
    fetchStatuses,
}: LeaderBoardProps) => {
    const [currCircuitId, setCurrCircuitId] = useState<string>(defaultCircuit);
    const [statuses, setStatuses] = useState<CircuitStatuses>({circuitId:"",rankedStatuses:[]});
    const [selectedTeam, setSelectedTeam] = useState<TeamCircuitStatus|undefined>(undefined);

    const handleCircuitSelectionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrCircuitId(e.target.value);
    }

    const getCurrentCircuit = (): Circuit => {
        return circuits.find((c) => c.id === currCircuitId) || circuits[0];
    }

    const getDungeonStatus = (id: string, teamStatus: TeamCircuitStatus): DungeonStatus | undefined => {
        return teamStatus.dungeonStatus.find((ds) => ds.id === id);
    }

    const refreshTeamStatuses = (circuitId: string) => {
        fetchStatuses(circuitId).then((statuses) => {
            setStatuses(statuses);
        })
    }

    useEffect(() => {
        refreshTeamStatuses(currCircuitId);

        const interval = setInterval(() => {
            refreshTeamStatuses(currCircuitId);
        }, 30000)
    
        return () => {
          clearInterval(interval);
        };
      }, [currCircuitId]);

    useEffect(() => {
        if (selectedTeam !== undefined) {
            const updatedTeam = statuses.rankedStatuses.find((ts) => ts.team.id === selectedTeam.team.id);
            setSelectedTeam(updatedTeam);
        }
    }, [statuses])

    return (
    <div className="hcas">
        <h1>Leaderboard <span className="subtitle">(unofficial)</span></h1>
        <select value={currCircuitId} onChange={handleCircuitSelectionChanged}>
            {circuits.map((circuit: Circuit) => (
                <option value={circuit.id}>{circuit.name}</option>
            ))}
        </select>
        <button onClick={() => {
            refreshTeamStatuses(currCircuitId)
        }}>Refresh</button>
        <div className="statusArea">
            <table>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Team</th>
                        <th>Faction</th>
                        <th>Circuit Time</th>
                        {getCurrentCircuit().dungeons.map((dungeon) => (
                            <th>{dungeon.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {statuses.rankedStatuses.map((status, index) => (
                        <tr key={`${currCircuitId}-${status.team.id}`} className={`rank-${index+1}`} onClick={() => {
                            setSelectedTeam(status);
                        }}>
                            <td><span>{index+1}</span></td>
                            <td className="team"><span>{status.team.name}</span></td>
                            <td className={`faction-${status.team.faction.toLowerCase()}`}><span>{status.team.faction}</span></td>
                            <td><span>{renderSecondsAsTime(status.finalRunDurationSecs)}</span></td>
                            {getCurrentCircuit().dungeons.map((dungeon) => (
                                <td key={`${currCircuitId}-${status.team.id}-${dungeon.id}`} className="dstats">
                                    <DungeonStatusComponent dungeonStatus={getDungeonStatus(dungeon.id, status)}/>
                                </td>
                            ))}
                        </tr>
                    ))}

                </tbody>
            </table>
            <SidebarComponent teamCircuitStatus={selectedTeam}/>
        </div>
    </div>
  );
};

class KillCountStatus {
    expected: number;
    actual: number;
    remaining: number;

    constructor(expected: number, actual: number, remaining: number) {
        this.expected = expected;
        this.actual = actual;
        this.remaining = remaining;
    }
}

const getBossStatusRatio = (bosses: BossStatus[]): KillCountStatus => {
    const killCount = bosses.filter((boss) => boss.killTime > 0).length

    return new KillCountStatus(bosses.length, killCount, bosses.length - killCount);
}

interface DungeonStatusCompoentProps {
    dungeonStatus?: DungeonStatus
}

const DungeonStatusComponent = ({
    dungeonStatus,
}: DungeonStatusCompoentProps) => { 
    const bossKills = getBossStatusRatio(dungeonStatus?.bosses || []);
    const trashKillsRemaining = dungeonStatus?.trash.map((ts) => ts.remaining).reduce(
        (trashRemaining, currentValue) => trashRemaining + currentValue,
        0,
    )

    return (
        <div>
        <span>{renderSecondsAsTime(dungeonStatus?.runDurationSecs || 0)}</span>
        { (dungeonStatus?.runDurationSecs || 0 > 0) && (
            <span className="killRatios">
                (
                <span className="trashKillRatio">{trashKillsRemaining}</span>&middot;
                <span className="bossKillRatio">{bossKills.remaining}</span>
                )
            </span>
        )}
        </div>
    )
};

interface SidebarComponentProps {
    teamCircuitStatus?: TeamCircuitStatus,
}
const SidebarComponent = ({
    teamCircuitStatus,
}: SidebarComponentProps) => {
    if (teamCircuitStatus === undefined) {
        return (
            <div className="sidebar">    
            </div>
        )
    }

    return (
        <div className="sidebar">
            <span className="team">Team: {teamCircuitStatus.team.name}</span>
            {teamCircuitStatus.dungeonStatus.map((dungeon) => (
                <div key={dungeon.id} className={`dungeon ${dungeon.completed ? 'done' : ''}`}>
                    <span className="dungeonName">{dungeon.name}</span>
                    <table className="bosses">
                        <thead>
                            <tr>
                                <th colSpan={2}>Bosses</th>
                            </tr>
                        </thead>
                        <tbody>
                        {dungeon.bosses.map((boss) => (
                        <tr key={boss.id} className={boss.killTime > 0 ? 'done' : ''}>
                            <th className="bossName">{boss.name}</th>
                            <td>{renderSecondsAsTime(boss.runDurationSecsAtKill)}</td>
                        </tr>  
                        ))}
                        </tbody>                      
                    </table>
                    <table className="trashMobs">
                        <thead>
                            <tr>
                                <th colSpan={3}>Trash Mobs</th>
                            </tr>
                        </thead>
                        <tbody>
                        {dungeon.trash.map((trash) => (
                        <tr key={trash.id} className={trash.remaining == 0 ? 'done' : ''}>
                            <th className="trashName">{trash.name}</th>
                            <td className="trashRemaining">{trash.remaining}</td>
                            <td className="killRatio">{trash.killed}/{trash.required}</td>
                        </tr>  
                        ))}
                        </tbody>                      
                    </table>
                </div>
            ))}
        </div>
    )
};

export default LeaderBoard;