import React from "react";
import {useState, useEffect} from 'react';
import "./oauth.css";
import { ApiResponse, HttpApiResponse } from "../../types";
import logo from './logo192.png';

export interface OAuthConsentRequest {
    clientId: string
    state: string
    redirectUri: string
    responseType: string
    scope: string
    status: string
}

export interface OAuthConsentProps {
    idToken: string,
    consentReq: OAuthConsentRequest
    playerName: string
    fetchConsentDetails: ((idToken: string, consentReq: OAuthConsentRequest) => Promise<ApiResponse<ConsentDetails>>) | undefined
    finishConsentRequest: ((idToken: string, consentReq: OAuthConsentRequest) => Promise<ApiResponse<OAuthConsentAuthorizeResponse>>) | undefined
}

export interface OAuthConsentAuthorizeResponse {
    redirectUri: string,
}

const fetchConsentDetailsFromApi = (idToken: string, consentReq: OAuthConsentRequest): Promise<ApiResponse<ConsentDetails>> => {
    let resp: Response = new Response();
    return fetch('/v0/oauth2/prompt', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(consentReq)
      })
      .then((response) => {
        resp = response;
        return response.json()
      })
      .then((body: any) => new HttpApiResponse(resp, body))
}

const finishConsentRequestFromApi = (idToken: string, consentReq: OAuthConsentRequest): Promise<ApiResponse<OAuthConsentAuthorizeResponse>> => {
    let resp: Response = new Response();
    return fetch('/v0/oauth2/authorize', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(consentReq)
      })
      .then((response) => {
        resp = response;
        return response.json()
      })
      .then((body: any) => new HttpApiResponse(resp, body))
}

/**
 * Page component for OAuth Prompt.
 */
const OAuthConsent = ({
    idToken,
    consentReq,
    playerName,
    fetchConsentDetails,
    finishConsentRequest,
}: OAuthConsentProps) => {
    const fetchConsentDetailsFn = fetchConsentDetails || fetchConsentDetailsFromApi;
    const finishConsentRequestFn = finishConsentRequest || finishConsentRequestFromApi;

    const [consentDetails, setConsentDetails] = useState<ConsentDetails | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [sendingResponse, setSendingResponse] = useState<boolean>(false)

    useEffect(() => {
        if (consentDetails === undefined) {
            fetchConsentDetailsFn(idToken, consentReq)
                .then((cdResp) => {
                    if (cdResp.ok && cdResp.value !== undefined)  {
                        setConsentDetails(cdResp.value);
                    } else {
                        setErrorMessage(cdResp.error || 'Unknown error occurred.');
                    }
                })
        }
    }, [])

    const handleConsentResponse = (status: string) => {
        setSendingResponse(true);

        const finishReq = {
            ...consentReq,
            status: status,
        }

        finishConsentRequestFn(idToken, finishReq)
            .then((res) => {
                if (res.ok && res.value !== undefined) {
                    window.location.href = res.value.redirectUri;
                } else {
                    setErrorMessage(res.error || 'Unknown error occurred.');
                }
            })
    }

    const handleApprove = () => {
        handleConsentResponse("approved");
    }

    const handleCancel = () => {
        handleConsentResponse("cancelled");
    }

    return (
        <>
            {errorMessage !== '' && (
                <div>
                    Failure: {errorMessage}
                </div>
            )}

            {(!sendingResponse && consentDetails !== undefined) && (
                <OAuthPrompt
                    consentDetails={consentDetails}
                    playerName={playerName}
                    onApprove={handleApprove}
                    onCancel={handleCancel}
                />
            )}

            {sendingResponse && (
                <div>sending...</div>
            )}
        </>
    )
}

export interface ClientApp {
    name: string
    id: string
}

export interface ConsentDetails {
    client: ClientApp
    access: string[]
    agreement?: string
}

export interface OAuthPromptProps {
    consentDetails: ConsentDetails;
    playerName: string,
    onApprove: () => void;
    onCancel: () => void;
}

/**
 * Page component for OAuth Consent details.
 */
const OAuthPrompt = ({
    consentDetails,
    playerName,
    onApprove,
    onCancel,
}: OAuthPromptProps) => {
    return (
        <div className="OAuthConsent">
            <h2>Access Request</h2>
            <div className="clientInfo">
                <img className="clientLogo" src={logo} alt={consentDetails.client.name}/>
                <span className="clientName">{consentDetails.client.name}</span> wants access to your Addons.wtf account:
                <p className="playerName">
                    {playerName}
                </p>
                <p>
                    This will allow <span className="clientName">{consentDetails.client.name}</span> to:
                </p>
                <ul>
                    {consentDetails.access.map((access) => (
                        <li>{access}</li>
                    ))}
                </ul>
            </div>
            {consentDetails.agreement && (
                <div>
                    By clicking "Appove" you authorize this access, and agree to the following:
                    <p>{consentDetails.agreement}</p>
                </div>
            )}
            <h4>
                Make sure you trust <span className="clientName">{consentDetails.client.name}</span>
            </h4>
            <p>Learn about how {consentDetails.client.name} will handle your data by reviewing 
            its <a target="_privacy" href="/privacy">privacy policies</a> and <a target="_privacy" href="/tos">terms of service</a>.</p>
            <div className="consentButtons">
                <button className="cancel" onClick={onCancel}>Cancel</button>
                <button className="approve" onClick={onApprove}>Approve</button>
            </div>
        </div>
    )
}

export default OAuthConsent;