import React from "react";
import {useState, useEffect} from 'react';
import "./signin.css";
import * as firebaseui from 'firebaseui';
import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/compat/app";
import logo from '../../logo.svg';

export interface SignInProps {
    app: firebase.app.App
}

/**
 * Page component for SignIn.
 */
const SignIn = ({
    app,
}: SignInProps) => {
    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth(app));
        ui.start(".firebase-auth-container", {
            signInFlow: 'popup',
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                },
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                },
            ],
            privacyPolicyUrl: "/privacy",
            tosUrl: "/tos",
        })
}, []);
    return (
        <div className="firebase-auth-container">
            <p>Addons.wtf - Watch the files</p>
            <img src={logo} className="logo" alt="logo" />
        </div>
    )
}

export default SignIn;