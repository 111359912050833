import React from "react";
import "./sync-app-docs.css";
import logo from '../../logo.svg';

export interface SyncAppDocsProps {
    page?: string,
}

/**
 * SyncAppDocs component.
 */
const SyncAppDocs = ({
    page,
}: SyncAppDocsProps) => {
    return (
        <div className="SyncAppDocs">
            <h3>Addons.wtf - Watch the files</h3>
            <img src={logo} className="logo" alt="logo" />
                {(page === 'demo') ? (
                    <div className="SyncAppDocsContent">
                        <h1>Addons.wtf Desktop Sync App: Demo</h1>
                        <p>
                          The Addons.wtf Desktop Sync App is used to synchronize files from your local computer to the Addons.wtf 
                          service, keeping them safe in case of data loss, and making them available to your other computers.
                        </p>
                        <p>
                          The "demo" mode allows you to try it out without needing to have World of Warcraft installed.
                        </p>
                        <h2>How to run in demo mode</h2>
                        <p>
                            To run in demo mode, pass <pre>demo</pre> as an argument to the binary. For example, in Windows,
                            from a Terminal prompt:
                            <pre>
                                C:\&gt;"%LOCALAPPDATA%\Programs\Addons.wtf Sync\addons-wtf-app.exe" demo
                            </pre>
                            This will launch the app with a "Demo" helper window. 
                        </p>
                        <p>
                            NOTE: The normal "Settings" window will also open, and will allow you to link your 
                            Addons.wtf account, and see where the demo WoW installation folder is created.
                        </p>
                        <h2>How to use the demo</h2>
                        <p>
                            Once running, the Sync app will be running as normal in the system tray, and there will be
                            a "Demo" window with a few buttons on it. 
                        </p>
                        <p>
                            Each button represents a game session for a single WoW character. The sessions are linear
                            so session 1 comes before session 2, then session 3, then session 4. 
                        </p>
                        <p>
                            Click one of the buttons to simulate that game session, and WoW writing the files to disk.
                        </p>
                        <p>
                            Wait for the files be uploaded by the Sync app. The "Status" submenu in the tray app will
                            update with the last updated time when files are uploaded.
                        </p>
                        <h2>How to see a "Rescue"</h2>
                        <p>
                            A "Rescue" is when the local file is older than what is in the service. So to use this feature,
                            Click session 4, and wait for it to be uploaded. Then click session 1 and wait for it to 
                            be uploaded. Finally, in the task bar, click "Rescue". You should then see a message 
                            decribing what was found to be newer on the server. 
                        </p>
                    </div>
                ) : (
                <div className="SyncAppDocsContent">
                    <h1>Addons.wtf Desktop Sync App</h1>
                    <p>
                      The Addons.wtf Desktop Sync App is used to synchronize files from your local computer to the Addons.wtf 
                      service, keeping them safe in case of data loss, and making them available to your other computers.
                    </p>
                    <h2>What does it do?</h2>
                    <p>
                      The app runs mostly as a system tray app, monitoring for changes in your World of Warcraft (WoW) Addon data files. Specifically, these are 
                      files that WoW Addons use to save data. 
                    </p>
                    <h2>What files are sent to the service?</h2>
                    <p>
                      You configure where the WoW installation folder is, and the sync app will look under that folder for the 
                      "WTF" folder, and then looks for addon data files. These addon data files are sent:
                      <table>
                        <tr>
                          <th>Addon</th>
                          <th>Name</th>
                          <th>File Path</th>
                        </tr>
                        <tr>
                          <td>Hardcore</td>
                          <td>Hardcore.lua</td>
                          <td>[WoW Install]/[WoW Flavor]/WTF/Account/[Account Name]/SavedVariables/Hardcore.lua</td>
                        </tr>
                        <tr>
                          <td>Hardcore</td>
                          <td>Hardcore.lua</td>
                          <td>[WoW Install]/[WoW Flavor]/WTF/Account/[Account Name]/[Realm Name]/[Character Name]/SavedVariables/Hardcore.lua</td>
                        </tr>
                        <tr>
                          <td>AutoBiographer</td>
                          <td>AutoBiographer.lua</td>
                          <td>[WoW Install]/[WoW Flavor]/WTF/Account/[Account Name]/SavedVariables/AutoBiographer.lua</td>
                        </tr>
                        <tr>
                          <td>AutoBiographer</td>
                          <td>AutoBiographer.lua</td>
                          <td>[WoW Install]/[WoW Flavor]/WTF/Account/[Account Name]/[Realm Name]/[Character Name]/SavedVariables/AutoBiographer.lua</td>
                        </tr>
                      </table>
                    </p>
                    <p>
                      Note that the "Account Name" is never sent to the service directly, a placeholder is sent instead. Character names as well as data in the lua files are 
                      sent to the service.
                    </p>
                    <h2>How do I use it?</h2>
                    <p>
                      To get the most out of the Sync App:
                      <ul>
                        <li>Install it</li>
                        <li>Make sure it is running. You can keep it running in the taskbar, so you don't forget to start it up.</li>
                        <li>Play WoW. (with the Hardcore and AutoBiographer addons)</li>
                        <li>While playing, periodically reload the ui (via a <code>/reload</code> console command. This saves data, and the Sync App notices and uploads the files.</li>
                      </ul>
                      Mostly, thats it. See the next section on how to restore files. Otherwise, we're working to add more features to the app.addons.wtf site to show things about your
                      character, and the community.
                    </p>
                    <h2>What happens then?</h2>
                    <p>
                      The service can help you to restore damaged or lost data files. Currently, this is NOT automatic (but notifications are in the works). If you have lost data
                      and want to pull the data back from the service, use the "Rescue" option from the task bar menu. The Rescue will go find any files that the service has that
                      are "better" than the ones you have locally. Generally, this means the service has newer files, but sometimes a newer file has been damaged or cleared out in 
                      some way. In that case, the service is able to find the "best" file available (for example, for Hardcore, this is generally the file with the highest tracked 
                      time).
                    </p>
                    <p>
                      The service will show you what files it has found that are better than what you have locally, and you can download them as a zip file to review them. After review
                      you can click a button to allow the service to replace the local files for you. NO files are replaced automatically without your permission. Further, when a file 
                      is replaced, a copy is made with at ".bak.wtf" extension.
                    </p>
                    <h2>What about multiple computers?</h2>
                    <p>If you play on multiple computers, you just need to be sure to "Sign In" to the Sync App with the same account, and then before each session on either computer, 
                      run a "Rescue" to be sure you have the latest data on the computer you are about to play on.
                    </p>
                    <h2>Why am I still missing data?</h2>
                    <p>
                      WoW will only ever save data when the UI is reloaded. This is generally only on logout or an explicit <code>/reload</code>. If you play a long session without reloading
                      and then experience a crash, all of that data is lost because it was never saved to disk. To help reduce this risk, we recommend that you reload regularly, at least every
                      20 minutes or so. 
                    </p>
                    <h2>What if I'm replacing my computer?</h2>  
                    <p>
                      Congrats! Here is what you'll want to do:
                      <ul>
                        <li>
                          First, you want to be sure you already had the Sync App running on your old computer(s) and all the character data has been sync'ed up.
                        </li>
                        <li>
                          After that, on the new computer, you'll want to install WoW, and the addons, and login as a single character on the WoW account you use.
                        </li>
                        <li>
                          Then logout of WoW and close it. 
                        </li>
                        <li>
                          Install the Addons.wtf Sync App, "Sign In" to it with the same Addons.wtf account and launch it.
                        </li>
                        <li>
                          In the Addons.wtf Sync App System Tray icon, click the "Rescue" menu option.
                        </li>
                      </ul>
                      
                      You should then see a page describing how many characters are being brought over. You can review the zip file, and then click "Apply" 
                      to have the App put them on the local disk for you.
                    </p>
                </div>
                )}
        </div>
    )
}

export default SyncAppDocs;