import React from 'react';
import {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged, signOut, User} from "firebase/auth";
import LeaderBoard from './components/hcas/LeaderBoard';
import OAuthConsent from './components/OAuthConsent';
import PrivacyPolicy from './components/PrivacyPolicy';
import SignIn from './components/SignIn';
import {Circuit, CircuitStatuses} from './components/hcas/types';
import { HttpApiResponse } from './types';
import { OAuthConsentRequest } from './components/OAuthConsent/OAuthConsent';
import TermsOfService from './components/TermsOfService';
import SyncAppDocs from './components/SyncAppDocs';

const firebaseConfig = {
  apiKey: "AIzaSyB8TRaeq3lcHSL4QfhUuGobSZHOX6L4FcY",  
  authDomain: "addons-wtf-001-c922.firebaseapp.com",
  projectId: "addons-wtf-001-c922",
  storageBucket: "addons-wtf-001-c922.appspot.com",
  messagingSenderId: "520995127210",
  appId: "1:520995127210:web:3ea3948230051fdaf575d3"
};

const app = firebase.initializeApp(firebaseConfig);

interface PageTab {
  id: string,
  title: string,
  requiresLogin: boolean,
}

const pages: Map<string, PageTab> = [
  {
    id: "login",
    title: "Sign In",
    requiresLogin: false,
  },
  {
    id: "leaderboard",
    title: "LeaderBoard",
    requiresLogin: false,
  },
  {
    id: "oauth-consent",
    title: "Access Request",
    requiresLogin: true,
  },
  {
    id: "privacy",
    title: "Privacy Policy",
    requiresLogin: false,
  },
  {
    id: "tos",
    title: "Terms of Service",
    requiresLogin: false,
  },
  {
    id: "sync-app-errors",
    title: "Sync App Errors",
    requiresLogin: false,
  },
  {
    id: "sync-app-info",
    title: "Sync App Docs",
    requiresLogin: false,
  },
  {
    id: "sync-app-demo",
    title: "Sync App Demo Docs",
    requiresLogin: false,
  }
].reduce((map, val) => {
  map.set(val.id, val);
  return map;
}, new Map<string, PageTab>());

const parsePageTab = (): string => {
  const queryParameters = new URLSearchParams(window.location.search)
  const qPage = queryParameters.get("page")
  if (qPage !== null && qPage !== '') {
    return qPage;
  }

  if (window.location.pathname.endsWith("/oauth2/authorize")) {
    return 'oauth-consent'
  }

  if (window.location.pathname.endsWith("/privacy")) {
    return 'privacy'
  }

  if (window.location.pathname.endsWith("/tos")) {
    return 'tos'
  }

  if (window.location.pathname.endsWith("/docs/sync_app_errors")) {
    return 'sync-app-errors'
  }

  if (window.location.pathname.endsWith("/docs/sync_app")) {
    return 'sync-app-info'
  }

  if (window.location.pathname.endsWith("/docs/demo")) {
    return 'sync-app-demo'
  }

  return 'login';
}

const notFoundPage: PageTab = {
  id: "not-found",
  title: "Not Found",
  requiresLogin: false,
}

const getPageTab = (id: string): PageTab => {
  return pages.get(id) || notFoundPage
}

const parseOauthConsent = (): OAuthConsentRequest => {
  const queryParameters = new URLSearchParams(window.location.search)
  return {
    clientId: queryParameters.get("client_id") || '',
    state: queryParameters.get("state") || '',
    redirectUri: queryParameters.get("redirect_uri") || '',
    responseType: queryParameters.get("response_type") || '',
    scope: queryParameters.get("scope") || '',
    status: '',
  }
}

const getUserName = (user: User | null): string => {
  return user?.displayName || user?.email || user?.uid || '';
}

function App() {
  const auth = getAuth(app);

  const [currUserName, setCurrUserName] = useState<string>(getUserName(auth?.currentUser));
  const [currPageTab, setCurrPageTab] = useState<PageTab>(getPageTab(parsePageTab()))
  const [oauthIdToken, setOauthIdToken] = useState<string>("")

  onAuthStateChanged(auth, (user) => {
    setCurrUserName(getUserName(user));
  });

  if (parsePageTab() === 'oauth-consent') {
    auth?.currentUser?.getIdToken(true).then((idToken) => {
      setOauthIdToken(idToken);
    })
  }

  const handleLogoutClicked = () => {
    signOut(auth);
  }

  const handleLeaderboardClicked = () => {
    setTimeout(() => {
      setCurrPageTab(getPageTab('leaderboard'));
    }, 100)
  }

  const handleCloseLeaderboardClicked = () => {
    setTimeout(() => {
      setCurrPageTab(getPageTab('login'));
    }, 100)
  }

  const callLeaderboardForCircuit = async (circuitId: string): Promise<CircuitStatuses> => {
    const params = new URLSearchParams({
        "circuit": circuitId,
    });

    try {
      let resp: Response = new Response();
      const token = await auth?.currentUser?.getIdToken();
      return fetch(`v0/hcas/leaderboard?${params.toString()}`, 
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then((response) => {
        resp = response;
        return response.json()
      })
      .then((body: any) => new HttpApiResponse(resp, body))
      .then((apiResp) => new Promise<CircuitStatuses>((resolve, reject) => {
        console.log('got api response', apiResp);
        if (apiResp.ok) {
          const statuses = apiResp.value as CircuitStatuses;
          statuses.circuitId = circuitId;
          console.log('got statuses back, force-set curcuitId on it', statuses);
          resolve(statuses);
        }
      }))      
    } catch (err) {
      return new Promise<CircuitStatuses>((resolve, reject) => {
      })
    }    
  }

  return (
    <>
    {(currUserName === '' && (currPageTab.id === 'login' || currPageTab.requiresLogin) && 
      <SignIn app={app} />
    ) || 
    ((currPageTab.id === 'leaderboard') && 
      <div className="LeaderBoard">
        <LeaderBoard
          circuits={allCircuits()}
          defaultCircuit="circuit-1"
          fetchStatuses={callLeaderboardForCircuit}
        />
      </div>
    ) ||
    ((currPageTab.id === 'oauth-consent' && oauthIdToken !== '') && 
      <OAuthConsent 
          idToken={oauthIdToken}
          playerName={currUserName}
          consentReq={parseOauthConsent()}
          finishConsentRequest={undefined}
          fetchConsentDetails={undefined}
        />
    ) ||
    ((currPageTab.id === 'privacy') && 
      <PrivacyPolicy />
    ) ||
    ((currPageTab.id === 'tos') &&
      <TermsOfService />
    ) ||
      ((currPageTab.id === 'sync-app-info') && 
      <SyncAppDocs />
      ) ||
      ((currPageTab.id === 'sync-app-demo') && 
      <SyncAppDocs page="demo"/>
      ) ||
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Addons.wtf - Watch the files
            </p>
              <p>
                <span>Welcome {currUserName}</span>
                <div>
                  <button onClick={() => {
                    handleLogoutClicked();
                  }}>Logout</button>
                  <button onClick={() => {
                    handleLeaderboardClicked()
                  }}>Leaderboard</button>
                </div>
              </p>
          </header>
        </div>
      }
    </>
  )
}

const allCircuits = (): Circuit[] => {
  return [{
            id:"world-tour",
            name: "World Tour",
            dungeons: [],
        },{
            id:"circuit-0",
            name: "Circuit 0",
            dungeons: [{
                id:"389",
                name:"Ragefire Chasm",
            }],
          },{
            id:"circuit-1",
            name: "Circuit 1",
            dungeons: [
              {
                id:"43",
                name:"Wailing Caverns",
              },{
                id:"36",
                name:"Deadmines",
              },{
                id:"33",
                name:"Shadowfang Keep",
              },{
                id:"48",
                name:"Blackfathom Deeps",
              }
            ],
        },{
          id:"circuit-2",
          name: "Circuit 2",
          dungeons: [
            {
              id:"18901",
              name:"SM Graveyard",
            },{
              id:"47",
              name:"Razorfen Kraul",
            },{
              id:"90",
              name:"Gnomeregan",
            },{
              id:"18902",
              name:"SM Library",
            }
          ],
        },{
          id:"circuit-3",
          name: "Circuit 3",
          dungeons: [
            {
              id:"129",
              name:"Razorfen Downs",
            },{
              id:"18904",
              name:"SM Armory",
            },{
              id:"18903",
              name:"SM Cathedral",
            },{
              id:"70",
              name:"Uldaman",
            }
          ],
        },{
          id:"circuit-4",
          name: "Circuit 4",
          dungeons: [
            {
              id:"209",
              name:"Zul'Farak",
            },{
              id:"349",
              name:"Maraudon",
            },{
              id:"109",
              name:"Sunken Temple",
            }
          ],
        },{
          id:"circuit-5",
          name: "Circuit 5",
          dungeons: [
            {
              id:"429",
              name:"DM East",
            },{
              id:"230",
              name:"Blackrock Depths",
            },{
              id:"229",
              name:"Lower Blackrock Spire",
            },{
              id:"329",
              name:"Stratholme Undead",
            }
          ],
        }
      ];
}


export default App;
