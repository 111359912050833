import React from "react";
import "./privacy-policy.css";
import logo from '../../logo.svg';

export interface PrivacyPolicyProps {
}

/**
 * PrivacyPolicy component.
 */
const PrivacyPolicy = ({
}: PrivacyPolicyProps) => {
    return (
        <div className="PrivacyPolicy">
            <h3>Addons.wtf - Watch the files</h3>
            <img src={logo} className="logo" alt="logo" />
            <div className="PrivacyContent">
                <h1>Privacy Policy</h1>
                <p>
                This Privacy Policy is effective immediately for users that sign up for accounts on or after March 25, 2023.
                </p>
                <p>
                This Privacy Policy describes our policies on the collection, use, and disclosure of information about 
                you in connection with your use of our services, including those offered through our websites, emails, 
                and mobile applications (collectively, the "Service"). The terms "we", "us", and "Addons.wtf" refer to 
                MMObeus LLC, a Colorado LLC with its headquarters in Boulder County, Colorado. When you use the Service, 
                you consent to our collection, use, and disclosure of information about you as described in this Privacy 
                Policy. 
                </p>
                <h4>1. INFORMATION WE COLLECT AND HOW WE USE IT</h4>
                <p>
                We may collect and store information about you in connection with your use of the Service, including any 
                information you transmit to or through the Service. We use that information to provide the Service's 
                functionality, fulfill your requests, improve the Service's quality, engage in research and analysis 
                relating to the Service, personalize your experience, track usage of the Service, display relevant
                advertising, market the Service, provide customer support, contact you, back up our systems, allow for 
                disaster recovery, enhance the security of the Service, and comply with legal obligations. Even when we 
                do not retain such information, it still must be transmitted to our servers initially and stored long 
                enough to process.
                </p>
                <p>
                Please also note:
                <ol>
                    <li>
                    Account Information: If you create an account, we may store and use the information you provide 
                    during that process, such as your email address, username, and other information you may provide 
                    with your account. We may publicly display your username. You can modify some of the information 
                    associated with your account through your account settings. If you believe that someone has created 
                    an unauthorized account depicting you or your likeness, you can request its removal by flagging it.
                    </li>
                    <li>
                    Public Content: Your contributions to the Service are intended for public consumption and are 
                    therefore viewable by the public, including your game logs and addon data. Your account profile is 
                    also intended for public consumption, as is some of your other activity through the Service, like 
                    your uploaded game logs and addon data. You can limit the public nature of some of these activities 
                    through your account settings. In accordance with Blizzard's User Agreement, characters, character 
                    names, character data, virtual items, character profile information are owned by the game company 
                    or its licensors. This information is not protected as personal information, and may be displayed on 
                    the site.
                    </li>
                    <li>
                    Communications: When you sign up for an account or use certain features, you are opting to receive 
                    messages from us. You can manage some of your messaging preferences through your account settings, 
                    but note that you cannot opt out of receiving certain administrative, transactional, or legal 
                    messages from us. For example, if you request a password reset, we may send you messages about your 
                    transaction using the contact information you provide. We may also track your actions in response to
                    the messages you receive from us or through the Service, such as whether you deleted, opened, or 
                    forwarded such messages. We may also store information that you provide through communications to 
                    us, including from phone calls, letters, emails and other electronic messages, or in person.
                    </li>
                    <li>
                    Transactions: If you initiate a transaction through the Service, we may collect and store 
                    information about you, such as your name, phone number, address, email, and payment information 
                    (such as a credit card number and expiration date), as well as any other information you provide to 
                    us, in order to process your transaction, send communications about them to you, and populate forms 
                    for future transactions. When you submit credit card numbers, we encrypt that information using 
                    industry standard technology.
                    </li>
                    <li>
                    Activity: We may store information about your use of the Service, such as your search activity, the 
                    pages you view, and the date and time of your visit. We also may store information that your 
                    computer or mobile device may provide to us in connection with your use of the Service, such as 
                    your browser type, type of computer or mobile device, browser language, IP address, WiFi information 
                    such as SSID, mobile carrier, phone number, unique device identifier, advertising identifier, 
                    location (including geolocation, beacon based location, and GPS location), and requested and 
                    referring URLs.
                    </li>
                    <li>
                    Different Devices: You may access the Service through different devices (e.g., your mobile phone or 
                    desktop computer) and different platforms (e.g., the Addons.wtf website or Addons.wtf Sync desktop 
                    app). The information that we collect and store through those different uses may be cross-referenced 
                    and combined, and your contributions through one Addons.wtf platform will typically be similarly 
                    visible and accessible through all other Addons.wtf platforms.
                    </li>
                </ol>
                </p>
                <h4>2. COOKIES</h4>
                <p>
                We may use cookies, web beacons, tags, scripts, local shared objects, advertising identifiers 
                (including mobile identifiers such as Apple's IDFA or Google's Advertising ID) and similar technology 
                ("Cookies") in connection with your use of the Service, third party websites, and mobile applications. 
                Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, 
                in emails we send to you, and on our web pages. Cookies may transmit information about you and your use 
                of the Service, such as your browser type, search preferences, IP address, data relating to 
                advertisements that have been displayed to you or that you have clicked on, and the date and time of 
                your use. Cookies may be persistent or stored only during an individual session. 
                </p>
                <h4>3. Third Parties</h4>
                <p>
                    Third parties may receive information about you as follows:
                    <ol>
                    <li>
                    Advertisers: We may allow third parties to use Cookies through the Service to collect the same type 
                    of information for the same purposes as we do. In doing so, we adhere to the Digital Advertising 
                    Alliance's Self-Regulatory Principles for Online Behavioral Advertising. Third parties may be able 
                    to associate the information they collect with other information they have about you from other 
                    sources. Additionally, we may share non-personally identifiable information from or about you with 
                    third parties, such as location data, advertising identifiers, or a cryptographic hash of a 
                    common account identifier (such as an email address) to facilitate the display of targeted 
                    advertising.
                    </li>
                    <li>
                    Aggregate or Anonymous Information: We may share user information in the aggregate with third 
                    parties, such as content distributors. For example, we may disclose the number of users that have 
                    been exposed to, or clicked on, advertisements.
                    </li>
                    <li>
                    Business Transfers: We may share information from or about you with our parent companies, 
                    subsidiaries, joint ventures, or other companies under common control, in which case we will require 
                    them to honor this Privacy Policy. If another company acquires Addons.wtf, or all or substantially 
                    all of our assets, that company will possess the same information, and will assume the rights and 
                    obligations with respect to that information as described in this Privacy Policy.
                    </li>
                    <li>
                    Investigations and Legal Disclosures: We may investigate and disclose information from or about you 
                    if we have a good faith belief that such investigation or disclosure: (a) is reasonably necessary 
                    to comply with legal process and law enforcement instructions and orders, such as a search warrant, 
                    subpoena, statute, judicial proceeding, or other legal process or law enforcement requests served 
                    on us; or (b) is helpful to prevent, investigate, or identify possible wrongdoing in connection with 
                    the Service.
                    </li>
                    <li>
                    Links: The Service may link to third party services, like another site's URL. Except as set forth 
                    herein, we do not share your personal information with them, and are not responsible for their 
                    privacy practices. We suggest you read the privacy policies on or applicable to all such third party 
                    services.              
                    </li>
                    <li>
                    Third Party Accounts: If you sign up for, or log into, Addons.wtf using a third party service
                    (e.g., Sign In With Google), we may receive information about you from such third party service.
                    </li>
                    </ol>
                </p>
                <h4>4. CONTROLLING YOUR PERSONAL DATA</h4>
                <p>
                    Other users may be able to identify you, or associate you with your account, if you include personal 
                    information in the content you post publicly. You can reduce the risk of being personally identified 
                    by using the Service pseudonymously, though doing so could detract from the credibility of your 
                    contributions to the Service. 
                </p>
                <h4>5. DATA RETENTION AND ACCOUNT TERMINATION</h4>
                <p>
                You can close your account by email. We will remove certain information from view and/or dissociate them 
                from your account profile, but we may retain information about you for the purposes authorized under 
                this Privacy Policy unless prohibited by law. For example, we may retain information to prevent, 
                investigate, or identify possible wrongdoing in connection with the Service or to comply with legal 
                obligations.
                </p>
                <h4>6. CHILDREN</h4>
                <p>
                    The Service is intended for general audiences and is not directed to children under 13. We do not 
                    knowingly collect personal information from children under 13. If you become aware that a child has 
                    provided us with personal information without parental consent, please contact us 
                    <a href="mailto:support@addons.wtf">here</a>. 
                    If we become aware that a child under 13 has provided us with personal information without parental 
                    consent, we take steps to remove such information and terminate the child's account.
                </p>
                <h4>7. SECURITY</h4>
                <p>
                    We use various safeguards to protect the personal information submitted to us, both during 
                    transmission and once we receive it. However, no method of transmission over the Internet or via 
                    mobile device, or method of electronic storage, is 100% secure. Therefore, while we strive to use 
                    commercially acceptable means to protect your personal information, we cannot guarantee its absolute
                    security.
                </p>
                <h4>8. CONTACT</h4>
                <p>
                You may contact us online concerning our Privacy Policy <a href="mailto:support@addons.wtf">here</a>.
                </p>
                <h4>9. MODIFICATIONS TO THIS PRIVACY POLICY</h4>
                <p>
                We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will
                govern our collection, use, and disclosure of information about you and will be located at 
                <a href="https://app.addons.wtf/privacy">https://app.addons.wtf/privacy</a>. If we make material changes 
                to this Privacy Policy, we will notify you by email or by posting a notice on the Service prior to the 
                effective date of the changes. By continuing to access or use the Service after those changes become 
                effective, you acknowledge the revised Privacy Policy.
                </p>
                <h4>10. CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS</h4>
                <p>
                    We do not disclose your personal information to third parties for the purpose of directly marketing
                    their goods or services to you unless you first agree to such disclosure. If you have any questions
                    regarding this policy, or would like to change your preferences, you may contact us at the address 
                    listed above in Section 8.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;