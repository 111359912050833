import React from "react";
import "./terms-of-service.css";
import logo from '../../logo.svg';

export interface TermsOfServiceProps {
}

/**
 * TermsOfService component.
 */
const TermsOfService = ({
}: TermsOfServiceProps) => {
    return (
        <div className="TermsOfService">
            <h3>Addons.wtf - Watch the files</h3>
            <img src={logo} className="logo" alt="logo" />
            <div className="TermsOfServiceContent">
                <h1>Terms of Service</h1>
                <p>
                Addons.wtf is a set of services that help you keep your Game addon data safe (for example, World of 
                Warcraft Classic's Addon Saved Variables), and to provide additional community features around it. 
                </p>
                <p>
                These terms set forth our legal obligations to each other. They apply to your use of our services.
                </p>
                <p>
                When we say "Addons.wtf," "MMObeus", "we," "us," and "our" in these terms, we mean MMObeus LLC.
                </p>
                <p>
                When we say "services" in these terms, we mean MMObeus's services, apps, websites, and other products.
                For example https://app.addons.wtf and https://api.addons.wtf , the Addons.wtf Desktop Sync App, etc.
                </p>
                <p>
                When we say "you" or "your," we mean you. If you're accessing our services on behalf of a legal entity 
                (like your employer), you agree that you have the authority to bind that entity to these terms, 
                and "you" and "your" will refer to that entity.
                </p>
                <h4>1. Your Agreement to these Terms of Service</h4>
                <p>
                PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A BINDING CONTRACT. Welcome to the services 
                operated by MMObeus LLC.
                consisting of the website 
                available at https://app.addons.wtf, and its network of websites, software applications, or any other 
                products or services offered by MMObeus (the "MMObeus Services"). Other services offered by MMObeus may be 
                subject to separate terms.
                </p>
                <p>
                When using the MMObeus Services, you will be subject to MMObeus's Privacy Policy and additional 
                guidelines or rules that are posted on the MMObeus Services, made available to you, or disclosed to you 
                in connection with specific services and features. We may also offer certain paid services, which are 
                subject to the MMObeus Terms of Sale as well as any additional terms or conditions that are disclosed to 
                you in connection with such services. All such terms and guidelines (the "Guidelines") are incorporated 
                into these Terms of Service by reference.
                </p>
                <p>
                The Terms of Service apply whether you are a user that registers an account with the MMObeus Services or 
                an unregistered user. You agree that by clicking "Sign In" or otherwise registering, downloading, 
                accessing, or using the MMObeus Services, you are entering into a legally binding agreement between you 
                and MMObeus regarding your use of the MMObeus Services. You acknowledge that you have read, understood, 
                and agree to be bound by these Terms of Service. If you do not agree to these Terms of Service, do not 
                access or otherwise use any of the MMObeus Services.
                </p>
                <p>
                When using MMObeus or opening an account with MMObeus on behalf of a company, entity, or organization 
                (collectively, "Subscribing Organization"), you represent and warrant that you: 
                (i) are an authorized representative of that Subscribing Organization with the authority to bind that 
                organization to these Terms of Service and grant the licenses set forth herein; and 
                (ii) agree to these Terms of Service on behalf of such Subscribing Organization.
                </p>
                <h4>2. Use of MMObeus Services by Minors and Blocked Persons</h4>
                <p>                
                The MMObeus Services are not available to persons under the age of 13. If you are between the ages of 13 
                and the age of legal majority in your jurisdiction of residence, you may only use the MMObeus Services 
                under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Service.
                </p>
                <p>
                The MMObeus Services are also not available to any users previously removed from the MMObeus Services by 
                MMObeus or to any persons barred from receiving them under the laws of the United States (such as its
                export and re-export restrictions and regulations) or applicable laws in any other jurisdiction.
                </p>
                <p>
                BY DOWNLOADING, INSTALLING, OR OTHERWISE USING THE MMObeus SERVICES, YOU REPRESENT THAT YOU ARE AT LEAST 
                13 YEARS OF AGE, THAT YOUR PARENT OR LEGAL GUARDIAN AGREES TO BE BOUND BY THESE TERMS OF SERVICE IF YOU 
                ARE BETWEEN 13 AND THE AGE OF LEGAL MAJORITY IN YOUR JURISDICTION OF RESIDENCE, AND THAT YOU HAVE NOT 
                BEEN PREVIOUSLY REMOVED FROM AND ARE NOT PROHIBITED FROM RECEIVING THE MMObeus SERVICES.
                </p>
                <h4>3. Privacy Policy</h4>
                <p>
                Your privacy is important to MMObeus. Please see our <a href="https://app.addons.wtf/privacy">Privacy 
                Policy</a> for information relating to how we collect, use, and disclose your personal information when 
                you use the MMObeus Services.
                </p>
                <h4>4. Account</h4>
                <h5>a. Account and Password</h5>
                <p>
                In order to open an account, you will be asked to provide us with certain information such as an account 
                email or name and a password.
                </p>
                <p>
                You are solely responsible for maintaining the confidentiality of your account, your password and for 
                restricting access to your computer. If you permit others to use your account credentials, you agree to 
                these Terms of Service on behalf of all other persons who use the Services under your account or 
                password, and you are responsible for all activities that occur under your account or password. Please 
                make sure the information you provide to MMObeus upon registration and at all other times is true,
                accurate, current, and complete to the best of your knowledge.
                </p>
                <p>
                Unless expressly permitted in writing by MMObeus, you may not sell, rent, lease, share, or provide access 
                to your account to anyone else, including without limitation, charging anyone for access to 
                administrative rights on your account. MMObeus reserves all available legal rights and remedies to 
                prevent unauthorized use of the MMObeus Services, including, but not limited to, technological barriers, 
                IP mapping, and, in serious cases, directly contacting your Internet Service Provider (ISP) regarding 
                such unauthorized use.
                </p>
                <h5>b. Third-Party Accounts</h5>
                <p>
                MMObeus may permit you to register for and log on to the MMObeus Services via certain third-party 
                services. The third party's collection, use, and disclosure of your information will be subject to that 
                third-party service's privacy notice. Further information about how MMObeus collects, uses, and discloses 
                your personal information when you link your MMObeus account with your account on any third-party service 
                can be found in our Privacy Policy.
                </p>
                <h5>c. Lost Account Access</h5>
                <p>
                If you get locked out of your account, we'll need to contact you at the email
                associated with your account. If your account is compromised or you no longer have access to your 
                email account, we may not be able to restore your access to your account or the data you've uploaded.
                </p>
                <h4>5. Use of Devices and Services</h4>
                <p>
                Access to the MMObeus Services may require the use of your personal computer or mobile device, as well as 
                communications with or use of space on such devices. You are responsible for any Internet connection or 
                mobile fees and charges that you incur when accessing the MMObeus Services.
                </p>
                <p>
                We're actively developing new features and products to improve Addons.wtf. As part of these efforts, we 
                may add or remove features, start offering new services, or stop offering some services entirely (or 
                just in some places or for some users) if they no longer make sense from a business perspective or 
                create risk for MMObeus, our users, or other third parties. While we try to avoid disruptions, we 
                cannot guarantee that there will not be an outage or change to the services, and your content may not 
                be retrievable due to such outages or changes. We are not liable for any such outages or service 
                changes.                    
                </p>
                <h4>6. Modification of these Terms of Service</h4>
                <p>
                MMObeus may amend any of the terms of these Terms of Service by posting the amended terms. Your continued 
                use of the MMObeus Services after the effective date of the revised Terms of Service constitutes your 
                acceptance of the terms.
                </p>
                <h4>7. License</h4>
                <p>
                The MMObeus Services are owned and operated by MMObeus. Unless otherwise indicated, all content, 
                information, and other materials on the MMObeus Services (excluding User Content, set out in Section 9 
                below), including, without limitation, MMObeus's trademarks and logos, the visual interfaces, graphics, 
                design, compilation, information, software, computer code (including source code or object code), 
                services, text, pictures, information, data, sound files, other files, and the selection and arrangement 
                thereof (collectively, the "Materials") are protected by relevant intellectual property and proprietary 
                rights and laws. All Materials are the property of MMObeus or its subsidiaries or affiliated companies 
                and/or third-party licensors. Unless otherwise expressly stated in writing by MMObeus, by agreeing to 
                these Terms of Service you are granted a limited, non-sublicensable license (i.e., a personal and 
                limited right) to access and use the MMObeus Services for your personal use or internal business use 
                only.
                </p>
                <p>
                MMObeus reserves all rights not expressly granted in these Terms of Service. This license is subject to 
                these Terms of Service and does not permit you to engage in any of the following: 
                (a) resale or commercial use of the MMObeus Services or the Materials; 
                (b) distribution, public performance or public display of any Materials; 
                (c) modifying or otherwise making any derivative uses of the MMObeus Services or the Materials, or any 
                portion of them; 
                (d) use of any data mining, robots, or similar data gathering or extraction methods; 
                (e) downloading (except page caching) of any portion of the MMObeus Services, the Materials, or any 
                information contained in them, except as expressly permitted on the MMObeus Services; or 
                (f) any use of the MMObeus Services or the Materials except for their intended purposes. 
                Any use of the MMObeus Services or the Materials except as specifically authorized in these Terms of 
                Service, without the prior written permission of MMObeus, is strictly prohibited and may violate 
                intellectual property rights or other laws. Unless explicitly stated in these Terms of Service, nothing 
                in them shall be interpreted as conferring any license to intellectual property rights, whether by 
                estoppel, implication, or other legal principles. MMObeus can terminate this license as set out in 
                Section 14.
                </p>
                <h4>8. Public Content</h4>
                <p>
                Your contributions to the Service are intended for public consumption and are therefore viewable by the 
                public, including your game logs and addon data. Your account profile is also intended for public 
                consumption, as is some of your other activity through the Service, like your uploaded game logs and 
                addon data. You can limit the public nature of some of these activities through your account settings. 
                </p>
                <h4>9. User Content</h4>
                <p>
                When we say "User Content" or "your content" in these terms, we mean all the things you add 
                (upload, post, share, stream, etc.) to our services. This includes text, links, GIFs, emoji, photos, 
                videos, documents, or other media. If we come up with another way for you to add content to the 
                services, it includes that too.
                </p>
                <p>
                In accordance with Blizzard's User Agreement, characters, character names, character data, virtual 
                items, character profile information are owned by the game company or its licensors. This information 
                is not protected as personal information, and may be displayed on the site.
                </p>
                <p>
                You don't have any obligation to add content to the services. If you choose to add content to the 
                services, you are responsible for ensuring that you have the right to do so, that you have the right to 
                grant the licenses in this section of these terms, and that your content is lawful. We take no 
                responsibility for any of your content, and we are not responsible for others' use of your content.
                </p>

                <h5>a. License to MMObeus</h5>
                <p>
                (i) Unless otherwise agreed to in a written agreement between you and MMObeus that was signed by an 
                authorized representative of MMObeus, if you submit, transmit, display, perform, post, or store User 
                Content using the MMObeus Services, you grant MMObeus and its sub-licensees, to the furthest extent and 
                for the maximum duration permitted by applicable law (including in perpetuity if permitted under 
                applicable law), an unrestricted, worldwide, irrevocable, fully sub-licenseable, nonexclusive, and 
                royalty-free right to: (a) use, reproduce, modify, adapt, publish, translate, create derivative works 
                from, distribute, perform, and display such User Content including without limitation for promoting 
                and redistributing part or all of the MMObeus Services (and derivative works thereof) in any form, 
                format, media, or media channels now known or later developed or discovered; and (b) use the name, 
                identity, likeness, and voice (or other biographical information) that you submit in connection with 
                such User Content. Should such User Content contain the name, identity, likeness, and voice (or other 
                biographical information) of third parties, you represent and warrant that you have obtained the 
                appropriate consents and/or licenses for your use of such features and that MMObeus and its 
                sub-licensees are allowed to use them to the extent indicated in these Terms of Service.
                </p>
                <p>
                (ii) With respect to User Content known as "add-ons", "maps", "mods", or other types of projects 
                submitted through CurseForge.com or related sites (the "Submitted Projects"), the rights granted by 
                you hereunder terminate once you remove or delete such Submitted Projects from the MMObeus Services. 
                You also acknowledge that MMObeus may retain, but not display, distribute, or perform, server copies 
                of Submitted Projects that have been removed or deleted.
                </p>

                <h5>b. User Content Representations and Warranties</h5>
                <p>
                You are solely responsible for your User Content and the consequences of posting or publishing it. You 
                represent and warrant that: (1) you are the creator or own or control all right in and to the User 
                Content or otherwise have sufficient rights and authority to grant the rights granted herein; (2) 
                your User Content does not and will not: (a) infringe, violate, or misappropriate any third-party right, 
                including any copyright, trademark, patent, trade secret, moral right, privacy right, right of 
                publicity, or any other intellectual property or proprietary right, or (b) defame any other person; 
                (3) your User Content does not contain any viruses, adware, spyware, worms, or other harmful or 
                malicious code; and (4) unless you have received prior written authorization, your User Content 
                specifically does not contain any pre-release or non-public beta software or game content or any 
                confidential information of MMObeus or third parties. MMObeus reserves all rights and remedies against 
                any users who breach these representations and warranties.
                </p>

                <h5>c. Content is Uploaded at Your Own Risk</h5>
                <p>
                MMObeus uses reasonable security measures in order to attempt to protect User Content against 
                unauthorized copying and distribution. However, MMObeus does not guarantee that any unauthorized 
                copying, use, or distribution of User Content by third parties will not take place. To the furthest 
                extent permitted by applicable law, you hereby agree that MMObeus shall not be liable for any 
                unauthorized copying, use, or distribution of User Content by third parties and release and forever 
                waive any claims you may have against MMObeus for any such unauthorized copying or usage of the User 
                Content, under any theory. THE SECURITY MEASURES TO PROTECT USER CONTENT USED BY MMObeus HEREIN ARE 
                PROVIDED AND USED "AS-IS" AND WITH NO WARRANTIES, GUARANTEES, CONDITIONS, ASSURANCES, OR OTHER TERMS 
                THAT SUCH SECURITY MEASURES WILL WITHSTAND ATTEMPTS TO EVADE SECURITY MECHANISMS OR THAT THERE WILL 
                BE NO CRACKS, DISABLEMENTS, OR OTHER CIRCUMVENTION OF SUCH SECURITY MEASURES.
                </p>

                <h5>d. Promotions</h5>
                <p>
                Users may promote, administer, or conduct a promotion (e.g., a contest or sweepstakes) on, through, or 
                utilizing the MMObeus Services (a "Promotion"). If you choose to promote, administer, or conduct a 
                Promotion, you must adhere to the following rules: (1) You may carry out Promotions to the extent 
                permitted by applicable law and you are solely responsible for ensuring that any Promotions comply with 
                any and all applicable laws, obligations, and restrictions; (2) You will be classified as the promoter 
                of your Promotion in the applicable jurisdiction(s) and you will be solely responsible for all aspects 
                of and expenses related to your Promotion, including without limitation the execution, administration,
                and operation of the Promotion; drafting and posting any official rules; selecting winners; issuing 
                prizes; and obtaining all necessary third-party permissions and approvals, including without limitation 
                filing any and all necessary registrations and bonds. MMObeus has the right to remove your Promotion 
                from the MMObeus Services if MMObeus reasonably believes that your Promotion does not comply with the 
                Terms of Service or applicable law; (3) MMObeus is not responsible for and does not endorse or support 
                any such Promotions. You may not indicate that MMObeus is a sponsor or co-sponsor of the Promotion; and 
                (4) You will display or read out the following disclaimer when promoting, administering, or conducting 
                a Promotion: "This is a promotion by [Your Name]. Addons.wtf does not sponsor or endorse this promotion 
                and is not responsible for it.".
                </p>

                <h5>e. Endorsements/Testimonials</h5>
                <p>
                You agree that your User Content will comply with the FTC's Guidelines Concerning the Use of 
                Testimonials and Endorsements in Advertising, the FTC's Disclosures Guide, the FTC's Native Advertising 
                Guidelines, and any other guidelines issued by the FTC from time to time (the "FTC Guidelines"), as 
                well as any other advertising guidelines required under applicable law. For example, if you have been 
                paid or provided with free products in exchange for discussing or promoting a product or service 
                through the MMObeus Services, or if you are an employee of a company and you decide to discuss or 
                promote that company's products or services through the MMObeus Services, you agree to comply with the 
                FTC Guidelines' requirements for disclosing such relationships. You, and not MMObeus, are solely 
                responsible for any endorsements or testimonials you make regarding any product or service through the 
                MMObeus Services.
                </p>

                <h5>f. Political Activity</h5>
                <p>
                Subject to these Terms of Service, you may share political opinions; 
                participate in political activity; provide links to a political committee's official website, including 
                the contribution page of a political committee; and solicit viewers to make contributions directly to a 
                political committee. You agree, however, that these activities are entirely your own. Moreover, by 
                engaging in these activities, you represent and warrant that you are eligible to engage in them under
                applicable law, and that you will abide by all relevant laws and regulations while doing so.
                </p>
                <p>
                You agree not to solicit the use of or use any MMObeus monetization tool
                for the purpose of making or delivering a contribution to a candidate, candidate's committee, political 
                action committee, ballot committee, or any other campaign committee, or otherwise for the purpose of 
                influencing any election. Candidates for political office are not eligible to use any MMObeus 
                monetization tool on their channels.
                </p>
                <h4>10. Prohibited Conduct</h4>
                <p>
                YOU AGREE NOT TO violate any law, contract, intellectual property, or other third-party right; not to 
                commit a tort, and that you are solely responsible for your conduct while on the MMObeus Services.
                </p>
                <p>
                You agree that you will comply with these Terms of Service and will not:
                </p>
                <p>
                i. create, upload, transmit, distribute, or store any content that is inaccurate, unlawful, infringing, 
                defamatory, obscene, pornographic, invasive of privacy or publicity rights, harassing, threatening, 
                abusive, inflammatory, or otherwise objectionable;                    
                </p>
                <p>
                ii. impersonate any person or entity; falsely claim an affiliation with any person or entity; access 
                the MMObeus Services accounts of others without permission; forge another person's digital signature; 
                misrepresent the source, identity, or content of information transmitted via the MMObeus Services; or 
                perform any other similar fraudulent activity;                    
                </p>
                <p>
                iii. send junk mail or spam to users of the MMObeus Services, including without limitation unsolicited 
                advertising, promotional materials, or other solicitation material; bulk mailing of commercial 
                advertising, chain mail, informational announcements, charity requests, petitions for signatures, 
                or any of the preceding things related to promotional giveaways (such as raffles and contests); 
                and other similar activities;
                </p>
                <p>
                iv. harvest or collect email addresses or other contact information of other users from the MMObeus 
                Services;
                </p>
                <p>
                v. defame, harass, abuse, threaten, or defraud users of the MMObeus Services, or collect or attempt to 
                collect, personal information about users or third parties without their consent;
                </p>
                <p>
                vi. delete, remove, circumvent, disable, damage, or otherwise interfere with (a) security-related 
                features of the MMObeus Services or User Content, (b) features that prevent or restrict use or copying 
                of any content accessible through the MMObeus Services, (c) features that enforce limitations on the use 
                of the MMObeus Services or User Content, or (d) the copyright or other proprietary rights notices on the 
                MMObeus Services or User Content;
                </p>
                <p>
                vii. reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code of the 
                MMObeus Services or any part thereof, except and only to the extent that this activity is expressly 
                permitted by the law of your jurisdiction of residence;
                </p>
                <p>
                viii. modify, adapt, translate, or create derivative works based upon the MMObeus Services or any part 
                thereof, except and only to the extent that such activity is expressly permitted by applicable law 
                notwithstanding this limitation;
                </p>
                <p>
                ix. interfere with or damage the operation of the MMObeus Services or any user's enjoyment of them, by 
                any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other 
                malicious code;
                </p>
                <p>
                x. relay email from a third party's mail servers without the permission of that third party;
                </p>
                <p>
                xi. access any website, server, software application, or other computer resource owned, used, and/or 
                licensed by MMObeus, including but not limited to the MMObeus Services, by means of any robot, spider, 
                scraper, crawler, or other automated means for any purpose, or bypass any measures MMObeus may use to 
                prevent or restrict access to any website, server, software application, or other computer resource 
                owned, used, and/or licensed by MMObeus, including but not limited to the MMObeus Services;
                </p>
                <p>
                xii. manipulate identifiers in order to disguise the origin of any User Content transmitted through the 
                MMObeus Services;
                </p>
                <p>
                xiii. interfere with or disrupt the MMObeus Services or servers or networks connected to the MMObeus 
                Services, or disobey any requirements, procedures, policies, or regulations of networks connected to 
                the MMObeus Services; use the MMObeus Services in any manner that could interfere with, disrupt, 
                negatively affect, or inhibit other users from fully enjoying the MMObeus Services, or that could 
                damage, disable, overburden, or impair the functioning of the MMObeus Services in any manner;
                </p>
                <p>
                xiv. use or attempt to use another user's account without authorization from that user and MMObeus;
                </p>
                <p>
                xv. attempt to circumvent any content filtering techniques we employ, or attempt to access any service 
                or area of the MMObeus Services that you are not authorized to access;
                </p>                
                <p>
                xvi. attempt to indicate in any manner, without our prior written permission, that you have a 
                relationship with us or that we have endorsed you or any products or services for any purpose; and
                </p>
                <p>
                xvii. use the MMObeus Services for any illegal purpose, or in violation of any local, state, national, 
                or international law or regulation, including without limitation laws governing intellectual property 
                and other proprietary rights, data protection, and privacy.
                </p>
                <p>
                To the extent permitted by applicable law, MMObeus takes no responsibility and assumes no liability for 
                any User Content or for any loss or damage resulting therefrom, nor is MMObeus liable for any mistakes, 
                defamation, slander, libel, omissions, falsehoods, obscenity, pornography, or profanity you may 
                encounter when using the MMObeus Services. Your use of the MMObeus Services is at your own risk. In 
                addition, these rules do not create any private right of action on the part of any third party or any 
                reasonable expectation that the MMObeus Services will not contain any content that is prohibited by such 
                rules.
                </p>
                <p>
                MMObeus is not liable for any statements or representations included in User Content. MMObeus does not 
                endorse any User Content, opinion, recommendation, or advice expressed therein, and MMObeus expressly 
                disclaims any and all liability in connection with User Content. To the fullest extent permitted by 
                applicable law, MMObeus reserves the right to remove, screen, or edit any User Content posted or stored 
                on the MMObeus Services at any time and without notice, including where such User Content violates these 
                Terms of Service or applicable law, and you are solely responsible for creating backup copies of and 
                replacing any User Content you post or store on the MMObeus Services at your sole cost and expense. 
                Any use of the MMObeus Services in violation of the foregoing violates these Terms of Service and may 
                result in, among other things, termination or suspension of your rights to use the MMObeus Services.
                </p>

                <h4>11. Trademarks</h4>
                <p>                
                MMObeus, the MMObeus logos, and any other product or service name, logo, or slogan used by MMObeus, and 
                the look and feel of the MMObeus Services, including all page headers, custom graphics, button icons, 
                and scripts, are trademarks or trade dress of MMObeus, and may not be used in whole or in part in 
                connection with any product or service that is not MMObeus's, in any manner that is likely to cause
                confusion among customers, or in any manner that disparages or discredits MMObeus, without our prior 
                written permission.
                </p>
                <p>
                All other trademarks referenced in the MMObeus Services are the property of their respective owners. 
                Reference on the MMObeus Services to any products, services, processes, or other information by trade 
                name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, 
                sponsorship, or recommendation thereof by us or any other affiliation.                    
                </p>

                <h4>12. Third-Party Content</h4>
                <p>
                In addition to the User Content, MMObeus may provide other third-party content on the MMObeus Services 
                (collectively, the "Third-Party Content"). MMObeus does not control or endorse any Third-Party Content 
                and makes no representation or warranties of any kind regarding the Third-Party Content, including 
                without limitation regarding its accuracy or completeness. Please be aware that we do not create 
                Third-Party Content, update, or monitor it. Therefore we are not responsible for any Third-Party 
                Content on the MMObeus Services.
                </p>                
                <p>
                You are responsible for deciding if you want to access or use third-party websites or applications that 
                link from the MMObeus Services (the "Reference Sites"). MMObeus does not control or endorse any such 
                Reference Sites or the information, materials, products, or services contained on or accessible through 
                Reference Sites, and makes no representations or warranties of any kind regarding the Reference Sites. 
                In addition, your correspondence or business dealings with, or participation in promotions of, 
                advertisers found on or through the MMObeus Services are solely between you and such advertiser. 
                Access and use of Reference Sites, including the information, materials, products, and services on or 
                available through Reference Sites is solely at your own risk.                    
                </p>

                <h4>13. Idea Submission</h4>
                <p>
                By submitting ideas, suggestions, documents, and/or proposals (the "Submissions") to MMObeus or its 
                employees, you acknowledge and agree that MMObeus shall be entitled to use or disclose such Submissions 
                for any purpose in any way without providing compensation or credit to you.
                </p>

                <h4>14. Termination</h4>
                <p>
                To the fullest extent permitted by applicable law, MMObeus reserves the right, without notice and in our 
                sole discretion, to terminate your license to use the MMObeus Services (including to post User Content) 
                and to block or prevent your future access to and use of the MMObeus Services, including where we 
                reasonably consider that: (a) your use of the MMObeus Services violates these Terms of Service or 
                applicable law; (b) you fraudulently use or misuse the MMObeus Services; or (c) we are unable to continue 
                providing the MMObeus Services to you due to technical or legitimate business reasons. Our right to 
                terminate your license includes the ability to terminate or to suspend your access to any purchased 
                products or services, including any subscriptions, or paid accounts. To the fullest 
                extent permitted by applicable law, your only remedy with respect to any dissatisfaction with: (i) the 
                MMObeus Services, (ii) any term of these Terms of Service, (iii) any policy or practice of MMObeus in 
                operating the MMObeus Services, or (iv) any content or information transmitted through the MMObeus 
                Services, is to terminate your account and to discontinue use of any and all parts of the MMObeus 
                Services.
                </p>

                <h4>15. Disputes</h4>

                <h5>a. Indemnification</h5>
                <p>
                To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless 
                MMObeus, its affiliated companies, and each of our respective contractors, employees, officers, 
                directors, agents, third-party suppliers, licensors, and partners (individually and collectively, the 
                "MMObeus Parties") from any claims, losses, damages, demands, expenses, costs, and liabilities, 
                including legal fees and expenses, arising out of or related to your access, use, or misuse of the
                MMObeus Services, any User Content you post, store, or otherwise transmit in or through the MMObeus 
                Services, your violation of the rights of any third party, any violation by you of these Terms of 
                Service, or any breach of the representations, warranties, and covenants made by you herein. 
                You agree to promptly notify the MMObeus Parties of any third-party claim, and MMObeus reserves the 
                right, at your expense, to assume the exclusive defense and control of any matter for which you are 
                required to indemnify MMObeus, and you agree to cooperate with MMObeus's defense of these claims. MMObeus 
                will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware 
                of it.
                </p>

                <h5>b. Disclaimers; No Warranties</h5>
                <p>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) THE MMObeus SERVICES AND THE CONTENT AND MATERIALS 
                CONTAINED THEREIN ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR 
                IMPLIED, EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY MMObeus; (B) THE MMObeus PARTIES 
                DISCLAIM ALL OTHER WARRANTIES, STATUTORY, EXPRESS, OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED 
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT AS TO THE 
                MMObeus SERVICES, INCLUDING ANY INFORMATION, CONTENT, OR MATERIALS CONTAINED THEREIN; (C) MMObeus DOES 
                NOT REPRESENT OR WARRANT THAT THE CONTENT OR MATERIALS ON THE MMObeus SERVICES ARE ACCURATE, COMPLETE, 
                RELIABLE, CURRENT, OR ERROR-FREE; (D) MMObeus IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS 
                RELATING TO TEXT OR PHOTOGRAPHY; AND (E) WHILE MMObeus ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE MMObeus 
                SERVICES SAFE, MMObeus CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE MMObeus SERVICES OR OUR SERVER(S) 
                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, AND THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED 
                SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD. NO ADVICE OR INFORMATION, WHETHER ORAL OR 
                WRITTEN, OBTAINED BY YOU FROM MMObeus OR THROUGH THE MMObeus SERVICES WILL CREATE ANY WARRANTY NOT 
                EXPRESSLY STATED HEREIN.
                </p>

                <h5>c. Limitation of Liability and Damages</h5>
                <p>i. Limitation of Liability</p>
                <p>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) IN NO EVENT SHALL MMObeus OR THE MMObeus PARTIES 
                BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY 
                KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION 
                IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY 
                CONNECTED WITH THE USE OF OR INABILITY TO USE THE MMObeus SERVICES, THE CONTENT OR THE MATERIALS, 
                INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE ON ANY INFORMATION 
                OBTAINED FROM MMObeus, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR 
                EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE,
                WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED 
                ACCESS TO MMObeus'S RECORDS, PROGRAMS, OR SERVICES; AND (B) IN NO EVENT SHALL THE AGGREGATE LIABILITY OF 
                MMObeus, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), 
                PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF OR 
                INABILITY TO USE THE MMObeus SERVICES EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE MMObeus 
                SERVICES DURING THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE HUNDRED 
                DOLLARS, WHICHEVER IS GREATER. TO THE EXTENT THAT APPLICABLE LAW PROHIBITS LIMITATION OF SUCH LIABILITY, 
                MMObeus SHALL LIMIT ITS LIABILITY TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW.
                </p>
                <p>ii. Reference Sites</p>
                <p>
                THESE LIMITATIONS OF LIABILITY ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY YOU BY REASON OF ANY 
                PRODUCTS OR SERVICES SOLD OR PROVIDED ON ANY REFERENCE SITES OR OTHERWISE BY THIRD PARTIES OTHER THAN 
                MMObeus AND RECEIVED THROUGH OR ADVERTISED ON THE MMObeus SERVICES OR RECEIVED THROUGH ANY REFERENCE 
                SITES.
                </p>
                <p>iii. Basis of the Bargain</p>
                <p>
                YOU ACKNOWLEDGE AND AGREE THAT MMObeus HAS OFFERED THE MMObeus SERVICES, USER CONTENT, MATERIALS, AND 
                OTHER CONTENT AND INFORMATION, SET ITS PRICES, AND ENTERED INTO THESE TERMS OF SERVICE IN RELIANCE UPON 
                THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS 
                AND LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN 
                YOU AND MMObeus, AND THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN 
                ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND MMObeus. MMObeus WOULD NOT BE ABLE TO PROVIDE THE MMObeus 
                SERVICES TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.
                </p>

                <h5>d. Applicable Law and Venue</h5>
                <p>
                (i) To the fullest extent permitted by applicable law, you and MMObeus agree that if you are a 
                Subscribing Organization or a consumer resident of a jurisdiction other than those in (ii) below, the 
                following governing law and arbitration provision applies:
                </p>
                <p>
                PLEASE READ THE FOLLOWING CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE DISPUTES WITH MMObeus AND 
                LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM MMObeus.
                </p>
                <p>
                You and MMObeus agree to arbitrate any dispute arising from these Terms of Service or your use of the 
                MMObeus Services, except that you and MMObeus are not required to arbitrate any dispute in which either 
                party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade 
                names, logos, trade secrets, or patents. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A 
                JURY TRIAL. You and MMObeus agree that you will notify each other in writing of any dispute within 
                thirty (30) days of when it arises. Notice to MMObeus shall be sent to: 
                MMObeus, 2770 Arapahoe Rd Ste 132 - 1179, Lafayette CO. 80026.
                </p>
                <p>
                You and MMObeus further agree: to 
                attempt informal resolution prior to any demand for arbitration; that any arbitration will occur in 
                Boulder County, Colorado; that arbitration will be conducted confidentially by a single 
                arbitrator in accordance with the rules of JAMS; and that the state or federal courts in Boulder 
                County, Colorado have exclusive jurisdiction over any appeals of an arbitration award and over any 
                suit between the parties not subject to arbitration. Other than class procedures and remedies discussed 
                below, the arbitrator has the authority to grant any remedy that would otherwise be available in court. 
                Any dispute between the parties will be governed by this Agreement and the laws of the State of 
                Colorado and applicable United States law, without giving effect to any conflict of laws principles 
                that may provide for the application of the law of another jurisdiction. Whether the dispute is heard 
                in arbitration or in court, you and MMObeus will not commence against the other a class action, class 
                arbitration, or other representative action or proceeding.
                </p>
                <p>
                (ii) If you are a resident in any jurisdiction in which the provision in the section above is found to 
                be unenforceable, then any disputes, claims, or causes of action arising out of or in connection with 
                these Terms of Service will be governed by and construed under the laws of your jurisdiction of 
                residence, and shall be resolved by competent civil courts within your jurisdiction of residence.
                </p>

                <h5>e. Claims</h5>
                <p>YOU AND MMObeus AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE MMObeus SERVICES MUST 
                    COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS 
                    PERMANENTLY BARRED.</p>


                <h4>16. Miscellaneous</h4>
                <h5>a. Waiver</h5>
                <p>
                If we fail to exercise or enforce any right or provision of these Terms of Service, it will not 
                constitute a waiver of such right or provision. Any waiver of any provision of these Terms of Service 
                will be effective only if in writing and signed by the relevant party.
                </p>

                <h5>b. Severability</h5>
                <p>
                If any provision of these Terms of Service is held to be unlawful, void, or for any reason 
                unenforceable, then that provision will be limited or eliminated from these Terms of Service to the 
                minimum extent necessary and will not affect the validity and enforceability of any remaining 
                provisions.
                </p>

                <h5>c. Assignment</h5>
                <p>
                These Terms of Service, and any rights and licenses granted hereunder, may not be transferred or 
                assigned by you, but may be assigned by MMObeus without restriction. Any assignment attempted to be made 
                in violation of this Terms of Service shall be void.
                </p>
                
                <h5>d. Survival</h5>
                <p>
                Upon termination of these Terms of Service, any provision which, by its nature or express terms should 
                survive, will survive such termination or expiration, including, but not limited to, Sections 7, 9, 11, 
                12, 15, 16.
                </p>

                <h5>e. Entire Agreement</h5>
                <p>
                The Terms of Service is the entire agreement between you and MMObeus relating to the subject matter 
                herein and will not be modified except by a writing signed by authorized representatives of both 
                parties, or by a change to these Terms of Service made by MMObeus as set forth in Section 6 above.
                </p>
            </div>
        </div>
    )
}

export default TermsOfService;