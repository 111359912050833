export interface ApiResponse<ResponseType> {
    value?: ResponseType;
    ok: boolean;
    error?: string;
}

export class HttpApiResponse<ResponseType> {
    value?: ResponseType;
    ok: boolean;
    error?: string;
 
    constructor(response: Response, body: any) {
      if (response.status == 200) {
        this.ok = true;
        try {
          this.value = body as ResponseType;
        } catch (e) {
          this.ok = false;
          this.error = parseErrorMessage(body, "json parse failure: " + e);
        }
      } else {
        this.ok = false;
        this.error = `got non-200 status: ${response.status}, ${response.text()}`
      }
    }
}

interface ApiError {
    code: string
    message: string
}

function parseErrorMessage(body: any, orDefault: string): string {
    try {
        const errResp = body as ApiError;

        return errResp.message;
    } catch (e) {
        return orDefault
    }
}
